import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Belmont({ styles, elements, ...props }) {
  const Elements = useComponents()
  const customFieldCount = elements.custom.length
  const stackedDisplay = customFieldCount > 0
  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page", { backgroundColor: "#ffffff" })}
    >
      <Elements.Heading
        className="formkit-banner"
        name="banner"
        defaults={{ content: "Add an extra call to action here" }}
      />
      <div className="formkit-images">
        <div className="formkit-images__item">
          <Elements.Image
            name="image_one"
            size={{ h: 390, w: 390 }}
            aspectRatio={1}
            shape="circle"
          />
        </div>
        <div className="formkit-images__item">
          <Elements.Image
            name="image_two"
            size={{ h: 390, w: 390 }}
            aspectRatio={1}
            shape="circle"
          />
        </div>
        <div className="formkit-images__item">
          <Elements.Image
            name="image_three"
            size={{ h: 390, w: 390 }}
            aspectRatio={1}
            shape="circle"
          />
        </div>
      </div>
      <div className="formkit-container">
        <Elements.Heading
          className="formkit-header"
          name="header"
          defaults={{ content: "Enter a catchy page headline" }}
        />
        <Elements.Region name="content" className="formkit-content">
          <Elements.Content
            defaults={{
              content: "Provide some more detail here"
            }}
          />
        </Elements.Region>
        <Elements.Form>
          <Elements.Errors />
          <Elements.CustomFields data-stacked={stackedDisplay}>
            <Elements.AddFieldButton />
            <Elements.Button
              name="submit"
              group="button"
              defaults={{ content: "Join waitlist" }}
            />
          </Elements.CustomFields>
        </Elements.Form>

        <Elements.Content
          name="disclaimer"
          defaults={{
            content: "We respect your privacy. Unsubscribe at any time."
          }}
        />
        <Elements.BuiltWith background="page" />
        <Elements.Content
          name="copyright"
          defaults={{
            content: `All rights reserved`
          }}
        />
      </div>
    </Elements.LandingPage>
  )
}

Belmont.style = "landing_page"
Belmont.categories = ["Waitlist"]
Belmont.thumbnail = ""
Belmont.preview = "https://pages.convertkit.com/aa89ae1057/04e213256b"
Belmont.fields = [
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Belmont, { name: "Belmont" })
